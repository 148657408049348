import React from 'react';

function NotFound() {
    return (
        <main>
            <h2>404 - Page Not Found</h2>
        </main>
    )
}

export default NotFound;