import Project from '../components/Project';
import React from 'react';

function Gallery() {

    return (
        <main>
            <Project />
        </main>
    )
}

export default Gallery;